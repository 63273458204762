import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Travel from '../views/Travel.vue'
import Special from '../views/Special.vue'
import Location from '../views/Location.vue'
import Interior from '../views/Interior'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/travel',
    name: 'Travel',
    component: Travel
  },
  {
    path: '/special',
    name: 'Special',
    component: Special
  },
  {
    path: '/location',
    name: 'Location',
    component: Location
  },
  {
    path: '/interior',
    name: 'Interior',
    component: Interior
  },
]

const router = new VueRouter({
  routes
})

export default router
