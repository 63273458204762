<script>
export default {
    name: "NavbarComponent",
    data() {
        return {
            drawer: false,
            routes: [
                { name: "Standort", route: "/location" },
                { name: "Besonderheiten", route: "/special" },
                { name: "Home", route: "/" },
                { name: "Anreise", route: "/travel" },
                { name: "Ausstattung", route: "/interior" },
            ],
            windowTop: 0,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll() {
            this.windowTop = window.top.scrollY;
        },
    },
};
</script>

<template>
    <div class="navbar">
        <!-- App Bar -->
        <v-app-bar fixed :color="windowTop < 600 - 48 ? 'transparent' : '#ba8856'" :flat="windowTop < 600 - 48" dark dense>
            <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-row align="center" justify="center" class="ma-2 hidden-sm-and-down">
                <div v-for="route in routes" :key="route.name">
                    <v-btn v-if="route.name == 'Home'" icon :to="route.route" class="mx-1"><v-icon>mdi-home</v-icon></v-btn>
                    <v-btn v-else text :to="route.route" class="mx-1">{{ route.name }}</v-btn>
                </div>
            </v-row>
        </v-app-bar>

        <v-img
            gradient="to top right, rgba(186, 136, 86,.7), rgba(72, 50, 25,.7)"
            class="py-12"
            src="../assets/hero.jpg"
            position="center center"
            height="600"
        >
            <v-container class="my-12">
                <v-img src="../assets/logo_light.png" height="300" contain class="my-12 mx-6"></v-img>
            </v-container>
        </v-img>

        <!-- Nav Drawer -->
        <v-navigation-drawer v-model="drawer" app dark temporary floating>
            <v-list nav dense>
                <v-img src="../assets/logo_light.png" height="100" contain class="my-2 mx-12"></v-img>
                <v-divider class="my-2"></v-divider>
                <v-list-item-group active-class="primary-color">
                    <v-list-item to="/">
                        <v-list-item-title class="text-center">Home</v-list-item-title>
                    </v-list-item>
                    <div v-for="route in routes" :key="route.name">
                        <v-list-item v-if="route.name != 'Home'" :to="route.route">
                            <v-list-item-title class="text-center">{{ route.name }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<style></style>
