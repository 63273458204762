<script>
export default {
    name: "InteriorView",
    data() {
        return {
            prices: [
                { amount: 1, price: 60 },
                { amount: 2, price: 80 },
                { amount: 3, price: 90 },
                { amount: 4, price: 100 },
                { amount: 5, price: 110 },
                { amount: 6, price: 120 },
                { amount: 7, price: 130 },
                { amount: 8, price: 140 },
            ],
        };
    },
};
</script>

<template>
    <div class="interior">
        <v-container class="py-12 px-6">
            <h1>Was findet ihr vor?</h1>
            <v-row>
                <v-col cols="12" md="6">
                    <ul>
                        <li>
                            Es ist Küchenwäsche , Frotteewäsche (Badkästli), Bettwäsche (Schrank Zimmer Bietschhorn), WC-Papier, Abwaschmittel und
                            Putzmittel vorhanden.
                        </li>
                        <li>Putzmaterial befindet sich rechts im Wohnzimmerschrank.</li>
                        <li>
                            Gewürze , Zucker und Salz, oder auch andere Lebensmittel welche ihr nur wenig braucht, dürfen mitbenutzt werden. Es hat
                            auch Vorräte an Lebensmitteln (Vorratsraum im Keller) welche ihr bei Bedarf brauchen dürft, wenn ihr grössere Mengen
                            braucht bitte ersetzen oder melden.
                        </li>
                        <li>Im Wohnzimmerschrank hat es Spiele, Zeichenmaterial Kläbi, Schnur etc.</li>
                        <li>Kissen für Gartenstühle befinden sich unten links im Wohnzimmerschrank.</li>
                        <li>
                            Gartenmöbel sind im Keller, bitte bei Abreise oder schlechtem Wetter (Wind) wieder versorgen. Bei nicht Gebrauch mit
                            Abdeckhülle vor UV Strahlen schützen.
                        </li>
                        <li>Im Geräteraum hat es 2 Paar Schneeschuhe zum benützen.</li>
                    </ul>
                </v-col>
                <v-col cols="12" md="6">
                    <v-img src="../assets/interior/interior.jpg" contain></v-img>
                </v-col>
            </v-row>

            <h3>Preise</h3>
            <div class="overline">Kurtaxen</div>
            <div class="body-2">Erwachsene: 4.80 CHF p.P / Nacht</div>
            <div class="body-2">Kinder: 2.40 CHF p.P / Nacht</div>
            <br />
            <div class="overline">Miete pro Nacht</div>
            <v-simple-table>
                <template #default>
                    <tbody>
                        <tr v-for="item in prices" :key="item.amount">
                            <td>{{ item.amount }} {{ item.amount == 1 ? "Person" : "Personen" }}</td>
                            <td>{{ item.price }} CHF</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <div class="overline mt-6">Bankverbindung</div>
            <div class="body-2">Zugunsten von: Gander Josef u/o Luzia</div>
            <div class="body-2">IBAN: CH32 8080 8003 2491 5964 0</div>
        </v-container>
    </div>
</template>

<style lang="scss">
ul {
    li {
        margin-bottom: 1rem;
    }
}
</style>
