<script>
export default {
    name: "SpecialView",
};
</script>

<template>
    <div class="special">
        <v-container class="py-12 px-6">
            <h1>Besonderheiten unseres Hauses</h1>
            <v-row class="mt-6">
                <v-col cols="12" md="6">
                    <h3>Strom & Wasser</h3>
                    <p>
                        Die Stromversorgung wird durch eine Solaranlage gewährleistet und ist dementsprechend nicht unbegrenzt verfügbar. Die
                        Warmwasser Aufbereitung wird durch eine thermische Solaranlage betrieben. An bewölkten Tagen sollte mit dem Warmwasser
                        dementsprechend sparsam umgegangen werden.
                    </p>

                    <h3>Heizung</h3>
                    <p>Zum Beheizen der Räumlichkeiten befindet sich ein Schwedenofen im Wohnzimmer. Brennholz steht zur Verfügung.</p>

                    <h3>Kochen & Backen</h3>
                    <p>Zum Kochen und Backen steht ein Gasherd sowie Gasbackofen zur Verfügung.</p>

                    <div class="overline mt-12">Mehr Infos zum Haus findet ihr im blauen Ordner im Stubenbuffet, Vitrine rechts unten.</div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-img src="../assets/special/solar.jpg" contain></v-img>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
