<script>
export default {
    name: "HomeView",
    data() {
        return {
            items: [
                { src: require("../assets/home/1.jpg") },
                { src: require("../assets/home/2.jpg") },
                { src: require("../assets/home/3.jpg") },
                { src: require("../assets/home/4.jpg") },
                { src: require("../assets/home/5.jpg") },
                { src: require("../assets/home/6.jpg") },
                { src: require("../assets/home/7.jpg") },
                { src: require("../assets/home/8.jpg") },
                { src: require("../assets/home/9.jpg") },
                { src: require("../assets/home/10.jpg") },
                { src: require("../assets/home/11.jpg") },
                { src: require("../assets/home/12.jpg") },
            ],
        };
    },
};
</script>

<template>
    <div class="home">
        <v-container class="py-12 px-6">
            <v-row>
                <v-spacer></v-spacer>
                <div class="home-content">
                    <h1>Liebe Feriengäste</h1>
                    <h3>hier einige Infos zu unserem Ferienhaus im Lötschental</h3>
                    <p>Herzlich Willkommen im Lötschental auf dem sonnigen Hochplateau Biel 2040 M.ü.M.</p>
                    <p>Ob sportliche Skiferien, Winterwandern oder Skitouren in verschneiter Winterlandschaft.</p>
                    <p>Ob spannende Biketouren, Wandern oder ein kühles Bad im Bergsee.</p>
                    <p>
                        Oder einfach die Seele baumeln lassen, den Alltag hinter sich lassen, die Stille und die wunderschöne Bergkulisse geniessen.
                    </p>
                    <p>Jede Jahreszeit bleibt unvergesslich!</p>
                </div>
                <v-spacer></v-spacer>
            </v-row>
        </v-container>

        <v-carousel hide-delimiters height="100vh">
            <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.src"
                reverse-transition="fade-transition"
                transition="fade-transition"
            ></v-carousel-item>
        </v-carousel>
    </div>
</template>

<style lang="scss">
.home-content {
    max-width: 620px;
}
</style>
