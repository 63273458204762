<script>
export default {
    name: "TravelView",
};
</script>

<template>
    <div class="travel">
        <v-container class="py-12 px-6">
            <h1>Anreise</h1>
            <v-row>
                <v-col cols="12" md="6">
                    <h3>Winter</h3>
                    <p>
                        Da die Strasse im Winter nicht geräumt wird ist das Chalet Bärgrösli nur mit der Lauchernalp-Bahn erreichbar. Die Parkgebühr
                        bei der Lauchernalp Talstation beträgt 8 CHF/Tag. Um von der Bergstation zum Chalet Bärgrösli zu gelangen, führt der Weg
                        leicht rechts an der Piste entlang hoch, auf der rechten Seite des Restaurants "Bergsunna" vorbei bis die Piste zum zweiten
                        mal von einem Weg gekreuzt wird. Dem zweiten Weg der die Piste kreuzt folgt man nun nach rechts bis zu einer starken
                        Linkskurve mit einer Bank. Ab da führt nun ein Winterwanderweg in die Senke und über den Bach Richtung Siedlung Biel. Beim
                        Begehen des Winterwanderwegs muss auf die aktuelle Lawinensituation sowie den Zustand des Weges geachtet werden. Bei erhöter
                        Lawinengefahr sollte der Weg über die angrenzende Siedlung Fischbiel gewählt werden. Es besteht zusätzlich die Möglichkeit ein
                        Schneetaxi zu buchen, welches von der Lauchernalp-Bahn angeboten wird.
                    </p>
                </v-col>
                <v-col cols="12" md="6">
                    <h3>Sommer</h3>
                    <p>
                        Ab der Bergstation Lauchernalp folgt man dem Lötschentaler Höhenweg Richtung Fafleralp. Nach einem Fussmarsch von ca. 20
                        Minuten erreicht man die Siedlung Biel.
                    </p>
                    <p>Bei Anreise mit dem Auto, muss bei der Talstation Lauchernalp eine Fahrbewilligung über die App Parkingpay gelöst werden.</p>
                    <div class="overline">Tarife Fahrbewilligung</div>
                    <div class="body-2">1 Tag: 20CHF</div>
                    <div class="body-2">3 Tage: 25CHF</div>
                    <div class="body-2">1 Woche: 35CHF</div>
                </v-col>
            </v-row>
            <iframe
                src="https://map.geo.admin.ch/embed.html?lang=de&topic=ech&bgLayer=ch.swisstopo.pixelkarte-farbe&layers=ch.swisstopo.zeitreihen,ch.bfs.gebaeude_wohnungs_register,ch.bav.haltestellen-oev,ch.swisstopo.swisstlm3d-wanderwege,KML%7C%7Chttps:%2F%2Fpublic.geo.admin.ch%2Fq3FyVE1wSkC_wD4uzq605w,ch.swisstopo.swissnames3d&layers_opacity=1,1,1,0.8,1,1&layers_visibility=false,false,false,true,true,true&layers_timestamp=18641231,,,,,&lon=7.78790&lat=46.40231&elevation=3254&heading=330.007&pitch=-40.731&catalogNodes=532,687"
                width="100%"
                height="600"
                frameborder="0"
                style="border: 0"
            ></iframe>
        </v-container>
    </div>
</template>
