<script>
export default {
    name: "LocationView",
};
</script>

<template>
    <div class="location">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11003.094685822089!2d7.765382772660275!3d46.413542887682915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478f0f4c5628e157%3A0x63a3cbedf44fb7f!2zQ2hhbGV0IELDpHJncsO2c2xp!5e0!3m2!1sde!2sch!4v1707664758786!5m2!1sde!2sch"
            width="100%"
            height="800"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
        />
    </div>
</template>

<style></style>
