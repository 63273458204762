<script>
import navbar from "./components/Navbar";
export default {
    name: "App",
    components: {
        navbar,
    },
};
</script>

<template>
    <v-app>
        <navbar />
        <router-view />
    </v-app>
</template>

<style lang="scss">
.primary-color {
    background-color: #ba8856;
}
</style>
