import { render, staticRenderFns } from "./Interior.vue?vue&type=template&id=7b48980a"
import script from "./Interior.vue?vue&type=script&lang=js"
export * from "./Interior.vue?vue&type=script&lang=js"
import style0 from "./Interior.vue?vue&type=style&index=0&id=7b48980a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VContainer,VImg,VRow,VSimpleTable})
